import ParentContainer from "./ParentContainer";
import React, { useEffect,useState } from "react";
import "babel-polyfill";
import { acquireNewTokenSilent, refreshPBI } from "./Authorization/AcquireTokenApi";
import {idTokenstring, LocalApiBaseUrl, trycatchAlertPopup} from './Shared/Constant';
// MSAL imports
import { MsalProvider,useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import _ from 'lodash';
import {initializeUserAccountData} from "./actions/userLoginAction";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { setSisenseScript } from './actions/appActions';
import axiosInstance from './Shared/interceptor';
import { TRACKING_MODULES, userTracker } from './Shared/Common';
import PrivacyModal from "./LandingPage/PrivacyModal";
const sisenseIsLoaded = () => {
  return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
}
const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}
function App ({storeLoginUserData, setSisenseScript }) {
  const { inProgress } = useMsal();
    const [userData, setData] = useState(null);
    const[islogginsucessfull,setislogginsucessfull]=useState(false);
    const[userRole,setUserRole]=useState(null);
    const isDevEnvironment=process.env.NODE_ENV === 'development';
    const opriaURL = process.env.REACT_APP_OPRIA_URL;

    const loadSisense = () => {
      const isLoaded = sisenseIsLoaded();
      if (isLoaded) {
          const Sisense = window.Sisense;
          Sisense.connect(opriaURL, true).then(app => {
            setSisenseScript({app, error: '', fetching: false, hasSisenseAccess: true});
          }).catch((error) => {
            setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
          })
      }
  }
  const handleSisenseError = (error) => {
    setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
  }

  const connect = (dataParam) => {
    if (!sisenseIsLoaded()) {
        fetch(`${opriaURL}js/sisense.js`)
        .then((response) => {
            if (response.status === 200) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src =`${opriaURL}js/sisense.js?v=${guid()}`
                script.async = true;
                script.onload = loadSisense;
                script.onerror = handleSisenseError;
                document.body.appendChild(script);
                
              } else {
                setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
              }
        }).catch((error) => {
            setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
        })
    }
}

   const GetUserAccessDetails = () => {
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetUserAccessDetails`)
        .then((res) => {
          const hasSisenseAccess = res.data.HasSisenseAccess;
          if (hasSisenseAccess) {
            setSisenseScript({app: null, error: '', fetching: true, hasSisenseAccess});
            setUserRole(res.data)
          } else {
            setSisenseScript({app: null, error: '', fetching: false, hasSisenseAccess: false});
            setUserRole(res.data)
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
    useEffect(()=>{
      connect()
    },[])
    useEffect(async () => {
        if (!isDevEnvironment &&!userData && inProgress === InteractionStatus.None) {
            let response=await acquireNewTokenSilent(false);
              setData(response);
              storeLoginUserData(response);
              let token=sessionStorage.getItem(idTokenstring);
              if(!_.isEmpty(token) && !_.isNil(token)){
                setislogginsucessfull(true);
                if(!sessionStorage.getItem('sessionActive')){
                  sessionStorage.setItem('sessionActive', 'yes'); /* For Local below code */                 
                }    
              }   
        }
    }, [inProgress, userData]);

    useEffect( () => {
      if(isDevEnvironment){
        sessionStorage.setItem('EnterpriseID', 'sujith.madhyastha'); /* For Local below code */
        sessionStorage.setItem(
          idTokenstring,
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJiMzBkNmFjNy0zZWI2LTQ4YjYtYmVmMy03MjA0MTA4ZDJkNWYiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjMyMTFkMGUtMTI1Yi00MmMzLTg2ZGItMzIyYjE5YTY1YTIyL3YyLjAiLCJpYXQiOjE2ODQ0ODczNDIsIm5iZiI6MTY4NDQ4NzM0MiwiZXhwIjoxNjg0NDkxMjQyLCJuYW1lIjoiS2FzaHlhcCBLYWxhd2FkZSwgU2Fua2V0Iiwibm9uY2UiOiI5NTAxZjEzNy01MDZmLTQ1YjEtYWY4MC1kYjRmNzRiMTUwYWIiLCJvaWQiOiJjMDJlYjhlNi01ZGYyLTQ3ZTMtYWViNi05NWEwMDAzNWUzZDgiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzLmthc2h5YXAua2FsYXdhZGVAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJyaCI6IjAuQVNZQURoMGg4MXNTdzBLRzJ6SXJHYVphSXNkcURiTzJQclpJdnZOeUJCQ05MVjhtQUQ4LiIsInJvbGVzIjpbIkRlZmF1bHRfQWNjZXNzIl0sInN1YiI6IkUyWWFBRGV0eG9oOEFXbUhZdmNITjd4VE9zeGljQ3M4ZjZEeko5cXNvVWsiLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1dGkiOiJ2RVpGV09CXzBrNjFmSXBZVHk0VkFBIiwidmVyIjoiMi4wIiwicGVvcGxla2V5IjoiMjM2NjAwNyJ9.jrKK8gdN7xlFcFfmZ_Z-5VLOA86JxnfwW_9aFJ3v8WH7VKB20-EbQWS_pWlX6tnMqStZCBrrUeQkAGwIQ3LYpPut6ezIwYIVotMIELhSbkA1PTV2PDqijHQt3y4PoAVDly2xHaRJ9uq3Vq5ViFDyy0fVRbxm9HhnUmjQQFl-qeY85oi6LtUN6lpcHTuVqhtJG18HVQjVFFiC-22kI7IadyGlAcCo6k9Gd9pa1h2moI29c7s-bkMy5gLlY3bjPPOHFRAbRMuYp6UP18mZbL64nbIXDVX271StpSibmA_d4NXyizgrMFcdyOYLxupdbYFJabwoiSYpsbDMR76xXfl7cg',
        );
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;
      } else if(islogginsucessfull && sessionStorage.getItem(idTokenstring) != undefined){
          const token = sessionStorage.getItem(idTokenstring);
          const base64Url = token.split('.')[1];//Token is a combination of header.payload.signature extracting payload here
          const base64 = base64Url.replace('-', '+').replace('_', '/');
          const tokendetails = JSON.parse(window.atob(base64));
          const { preferred_username } = tokendetails;
          const { peoplekey } = tokendetails;
          const EnterpriseId = preferred_username.split('@')[0];
          sessionStorage.setItem('EnterpriseID', EnterpriseId);
          sessionStorage.setItem('peoplekey', peoplekey);
          userTracker(TRACKING_MODULES.LOGIN, false)
      }
      if(islogginsucessfull || isDevEnvironment){
        GetUserAccessDetails();
     }
    },[islogginsucessfull, isDevEnvironment])

    useEffect(() => {
      if (!isDevEnvironment &&!userData && inProgress === InteractionStatus.None) {
      refreshPBI();
      }
    }, [inProgress, userData]);

    return (
            <>
              <div id="sisenseApp">
              {userRole && !_.isEmpty(userRole) && <ParentContainer userRole={userRole}/>}
              <PrivacyModal/>
              </div>
            </>
    );
}
const mapDispatchToProps=(dispatch)=>{
  return {
  storeLoginUserData:(payload)=>dispatch(initializeUserAccountData(payload)),
  setSisenseScript : (sisenseData) => dispatch(setSisenseScript(sisenseData))
  };
}
const mapStateToProps=(state)=>{
  return {
    userLoginData:state.userLoginDataReducer
  }
}
let connectFunc=connect(
  mapStateToProps,
  mapDispatchToProps
)
export default compose(connectFunc)(App);