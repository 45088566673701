import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import i18n from "i18next";
import SynopsLoader from "./SynopsLoader";
import { connect } from "react-redux";
import Access_denied from "../Images/Access_denied.svg";

const SisenseDashboardIframe = (props) => {
  const {
    data: { dashboardURL, clientName, offeringName },
    onClose,
    shouldDisableModelView,
    modalFullscreen,
  } = props;
  const [sisenseApp, setSisenseApp] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [widgetModal, setWidgetModal] = useState(false);
  const [dashboardL2, setDashboardL2] = useState("");
  const [frame, setFrame] = useState(null);
  const [domainURL, setDomainURL] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [view, setView] = useState("maximized");
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [shouldShowFiltersDrill, setShouldShowFiltersDrill] = useState(false);
  const [activeObj, setActiveObj] = useState({
    activeDahObj: null,
    activeWidgets: null,
    activeDivId: null,
  });
  const [isFilterPanelVisible, toggleFilterPanel] = useState(false);
  const [isFilterPanelVisibleDrill, toggleFilterPanelDrill] = useState(false);
  const [childView, setChildView] = useState("minimized");
  const [bankWgt, setBankWgt] = useState([
    process.env.REACT_APP_TASKMANAGER_ADOPTION_TREND_WIDGET_ID,
    process.env.REACT_APP_TASKMANAGER_FTE_WIDGET_ID,
    process.env.REACT_APP_TASKMANAGER_USER_WIDGET_ID,
  ]);
  const { app, error, fetching } =
    props.clientOpriaSisenseDetailsFromRedux.sisenseObj;
  const getDashboardID = (dashboardUrl) => {
    if (dashboardUrl.indexOf("/dashboards/") !== -1) {
      const splitPartsForURL = dashboardUrl.split("://");
      const protocol = splitPartsForURL[0];
      const domainName = splitPartsForURL[1].split("/")[0];
      const domainURL = `${protocol}://${domainName}`;

      const splitPartsForDashboardId = dashboardUrl.split("/dashboards/");
      let dashboardId = splitPartsForDashboardId[1];
      if (dashboardId.indexOf("?") !== -1) {
        const parts = dashboardId.split("?");
        dashboardId = parts[0];
      }
      if (dashboardId.indexOf("/") !== -1) {
        const parts = dashboardId.split("/");
        dashboardId = parts[0];
      }
      return [domainURL, dashboardId];
    } else {
      window.alert("Unable to find the dashboard id in the URL");
      onClose();
    }
  };

  useEffect(() => {
    let dashboardL1 = dashboardURL.split(",")[0];
    let dashboardL2 = dashboardURL.split(",")[1];
    if (dashboardL1 && dashboardL1.indexOf("/dashboards/") !== -1) {
      let dbId, domain;
      [domain, dbId] = getDashboardID(dashboardL1);
      setDomainURL(domain);
      setDashboardId(dbId);

      if (!shouldDisableModelView && dashboardL2 && dashboardL2.indexOf("/dashboards/") !== -1)
        setDashboardL2(getDashboardID(dashboardL2)[1]);
    } else {
      window.alert("Unable to find the dashboard id in the URL");
      onClose();
    }
  }, [dashboardURL, onClose]);

  useEffect(() => {
    if (!_.isEmpty(domainURL) && !_.isEmpty(dashboardId) && !fetching && app) {
      setSisenseApp(app);
    }
    if (!fetching && error && error.length) {
      setErrorMessage(error);
    }
  }, [domainURL, dashboardId, fetching, app]);

  useEffect(() => {
    if (sisenseApp) loadDash(sisenseApp);
  }, [sisenseApp, view]);

  function loadDash(app) {
    app.dashboards.load(dashboardId).then((dash) => {
      afterLoadDash(dash, dashboardId);
    });
  }

  function createRow(i) {
    const row = document.createElement("div");
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = `row${i}`;
    return row;
  }
  function createLayout(i, el, width, dashId, hidden) {
    const headerDiv = document.createElement("div");
    headerDiv.id = `head${el.widgetid}`;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.borderTopRightRadius = "10px";
    headerDiv.style.borderTopLeftRadius = "10px";
    headerDiv.style.backgroundColor = "#0242C6";

    const height =
      el.height === "" || el.height === 512
        ? "100%"
        : `${el.height.toString().replace("px", "")}px`;
    const div = document.createElement("div");
    div.id = el.widgetid;
    div.style.height = height;
    if (el.widgetid == "62bc54255e8ec600365dc57a") {
      div.style.backgroundColor = "#000441";
    } else {
    div.style.backgroundColor = "#2291FF";
    }
    const pdiv = document.createElement("div");
    pdiv.className = `layout row${i}`;
    pdiv.style.width = `calc(${width}% - 4px)`;
    pdiv.style.margin = "2px";
    pdiv.style.display = hidden ? "none" : "block";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  }
  function handleModal(drillDB) {
    if (sisenseApp) {
      widgetModalopen(true);
      sisenseApp.dashboards
        .load(drillDB)
        .then((dash) => afterLoadDash(dash, drillDB))
        .catch((error) => {
          if (error.status === 403) {
            setErrorMessage("You don't have access to Dashboards.");
          }
        });
    }
  }
  function loadWgt(db, widgets) {
    widgets.forEach((wgt, i) => {
      const div = document.getElementById(wgt);
      if (div) {
        div.style.width = "100%";
      }
      const oWgt = db.widgets.get(wgt);

      if (oWgt) {
        const head = document.getElementById(`head${wgt}`);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE") {
          if (head) {
            head.innerHTML = oWgt.title;
          }
        } else if (head) head.style.display = "none";

        if (!oWgt.container) {
          oWgt.container = div;
        }
        if (oWgt.$$model.options.drillTarget) {
          const drillDB = oWgt.$$model.options.drillTarget.oid
            ? oWgt.$$model.options.drillTarget.oid
            : oWgt.$$model.options.drillTarget;
          if (dashboardL2 && dashboardL2.length) {
            if (div) {
              div.onclick = () => handleModal(dashboardL2);
              div.style.cursor = "pointer";
            }
          } else if (drillDB !== "dummy") {
            if (div) {
              div.onclick = () => handleModal(drillDB);
              div.style.cursor = "pointer";
            }
          }
        }

        oWgt.refresh();

        const recurse = () => {
          if (oWgt.refreshing) setTimeout(recurse, 1000);
          else {
            //Banking dashboard
            oWgt.on("ready", () => {
              if (oWgt.type === "TabberByQbeeQ") {
                //console.log(div);
                const tabber = document.getElementsByClassName("listDefaultCSS");
                tabber[0].childNodes.forEach((el) => {
                  if (el.className === "listItemDefaultCSS")
                    el.onclick = (e) => {
                      clearstyle();
                      e.currentTarget.classList.add("tabberAct");
                      if (e.currentTarget.textContent.toLowerCase() === "user") {
                        hideWgt(process.env.REACT_APP_TASKMANAGER_FTE_WIDGET_ID);
                        showWgt(process.env.REACT_APP_TASKMANAGER_USER_WIDGET_ID);
                      } else {
                        showWgt(process.env.REACT_APP_TASKMANAGER_FTE_WIDGET_ID);
                        hideWgt(process.env.REACT_APP_TASKMANAGER_USER_WIDGET_ID);
                      }
                    };
                });
              }
            });
            const hideWgt = (wgt) => {
              const div = document.getElementById(wgt);
              if (div) div.parentElement.style.display = "none";
            };
            const showWgt = (wgt) => {
              const div = document.getElementById(wgt);
              if (div) div.parentElement.style.display = "block";
              const oWgt = db.widgets.get(wgt);
              oWgt.refresh();
            };

            const clearstyle = () => {
              const tabber = document.getElementsByClassName("listDefaultCSS");
              tabber[0].childNodes.forEach((el) => {
                el.removeAttribute("style");
                el.classList.remove("tabberAct");
              });
            };
          }
        };
        recurse();
      }
      if (i === widgets.length - 1) {
        setActiveObj({ activeDahObj: db, activeWidgets: widgets });
        db.refresh();
      }
    });
  }
  const applyFilter = (filt, value, db) => {
    const mem = Array.isArray(value) ? value : [value];

    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    const payload = {
      jaql: {
        title: filt,
        dim: "[Client." + filt + "]",
        datatype: "text",
        filter: {
          members: mem,
          multiSelection: true,
          explicit: true,
        },
      },
    };
    db.$$model.filters.update(payload, filterOptions);
  };

  const applyForce10ClientFilter = (
    db,
    applyClientFilter = false,
    applyTypeFilter = false,
    applyApplicationFilter = false
  ) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    let payload;
    if (!applyClientFilter && !applyTypeFilter && applyApplicationFilter) {
      payload = {
        jaql: {
          dim: "[Metric.Application]",
          datatype: "text",
          title: "Application",
          filter: { members: [props.selectedAssest] },
        },
      };
    }
    if (!applyClientFilter && applyTypeFilter && !applyApplicationFilter) {
      payload = {
        jaql: {
          title: "Type",
          dim: "[Client.Type]",
          datatype: "text",
          filter: {
            members: ["Demo"],
            multiSelection: true,
            explicit: true,
          },
        },
      };
    }
    if (!applyClientFilter && applyTypeFilter && applyApplicationFilter) {
      payload = [
        {
          levels: [
            {
              dim: "[Client.Type]",

              datatype: "text",

              title: "Type",

              filter: {
                explicit: true,

                userMultiSelect: false,

                multiSelection: false,

                members: ["Demo"],
              },
            },
            {
              dim: "[Metric.Application]",
              datatype: "text",
              title: "Application",
              filter: { members: [props.selectedAssest] },
            },
          ],
        },
      ];
    }
    if (applyClientFilter && !applyTypeFilter && !applyApplicationFilter) {
      payload = {
        jaql: {
          title: "Client",
          dim: "[Client.Client]",
          datatype: "text",
          filter: {
            members: ["FORCE02"],
            multiSelection: true,
            explicit: true,
          },
        },
      };
    }
    if (applyClientFilter && !applyTypeFilter && applyApplicationFilter) {
      payload = [
        {
          levels: [
            {
              dim: "[Client.Client]",

              datatype: "text",

              title: "Client",

              filter: {
                explicit: true,

                multiSelection: true,

                members: ["FORCE02"],
              },
            },
            {
              dim: "[Metric.Application]",
              datatype: "text",
              title: "Application",
              filter: { members: [props.selectedAssest] },
            },
          ],
        },
      ];
    }
    if (applyClientFilter && applyTypeFilter && !applyApplicationFilter) {
      payload = [
        {
          levels: [
            {
              dim: "[Client.Type]",

              datatype: "text",

              title: "Type",

              filter: {
                explicit: true,

                userMultiSelect: false,

                multiSelection: false,

                members: ["Demo"],
              },
            },

            {
              dim: "[Client.Client]",

              datatype: "text",

              title: "Client",

              filter: {
                explicit: true,

                multiSelection: true,

                members: ["FORCE02"],
              },
            },
          ],
        },
      ];
    }
    if (applyClientFilter && applyTypeFilter && applyApplicationFilter) {
      payload = [
        {
          levels: [
            {
              dim: "[Client.Type]",

              datatype: "text",

              title: "Type",

              filter: {
                explicit: true,

                userMultiSelect: false,

                multiSelection: false,

                members: ["Demo"],
              },
            },

            {
              dim: "[Client.Client]",

              datatype: "text",

              title: "Client",

              filter: {
                explicit: true,

                multiSelection: true,

                members: ["FORCE02"],
              },
            },
            {
              dim: "[Metric.Application]",
              datatype: "text",
              title: "Application",
              filter: { members: [props.selectedAssest] },
            },
          ],
        },
      ];
    }

    db.$$model.filters.update(payload, filterOptions);
  };
  function afterLoadDash(db, divId, dashId) {
    const filters =
      db.$$model.defaultFilters && db.$$model.defaultFilters.length
        ? db.$$model.defaultFilters
        : [];
    const applyClientFilter =
      filters.length &&
      filters.some(
        (filterObj) =>
          (filterObj.jaql && filterObj.jaql.dim && filterObj.jaql.dim === "[Client.Client]") ||
          (filterObj.levels &&
            filterObj.levels.some((levelObj) => levelObj.dim && levelObj.dim === "[Client.Client]"))
      );
    const applyTypeFilter =
      filters.length &&
      filters.some(
        (filterObj) =>
          (filterObj.jaql && filterObj.jaql.dim && filterObj.jaql.dim === "[Client.Type]") ||
          (filterObj.levels &&
            filterObj.levels.some((levelObj) => levelObj.dim && levelObj.dim === "[Client.Type]"))
      );
    const applyApplicationFilter =
      filters.length &&
      filters.some(
        (filterObj) =>
          (filterObj.jaql && filterObj.jaql.dim && filterObj.jaql.dim === "[Metric.Application]") ||
          (filterObj.levels &&
            filterObj.levels.some(
              (levelObj) => levelObj.dim && levelObj.dim === "[Metric.Application]"
            ))
      );
    if (db.$$model.title.startsWith("Drill")) {
      setShouldShowFiltersDrill(!!filters.length);
    } else {
      setShouldShowFilters(!!filters.length);
    }
    if (filters && filters.length && (applyClientFilter || applyTypeFilter)) {
      const client = JSON.parse(sessionStorage.getItem("clientSelection"));
      if (client && client.name && client.name.length) {
        if (client.name.trim().toLocaleLowerCase() === "force 10") {
          applyForce10ClientFilter(db, applyClientFilter, applyTypeFilter, applyApplicationFilter);
        } else if (applyClientFilter) {
          applyFilter("Client", client.name.trim(), db);
        }
      }
    }
    if (filters && filters.length) {
      if (db.$$model.title.startsWith("Drill")) {
        document.getElementById("filter-panel-drill").innerHTML = "";
        db.renderFilters(document.getElementById("filter-panel-drill"));
      } else {
        document.getElementById("filter-panel").innerHTML = "";
        db.renderFilters(document.getElementById("filter-panel"));
      }
    }
    const widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = db.$$model.title.startsWith("Drill")
      ? document.getElementById("widgetDB")
      : document.getElementById("sisenseAppModal");

    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach((col) => {
      i += 1;
      const row = createRow(i);
      col.subcells.forEach((cell) => {
        const width = bankWgt.includes(cell.elements[0].widgetid)
          ? 50
          : cell.width
          ? cell.width
          : 100;
        const hidden = cell.elements[0].widgetid === process.env.REACT_APP_TASKMANAGER_USER_WIDGET_ID ? true : false;
        row.appendChild(createLayout(i, cell.elements[0], width, dashId, hidden));
        widgets.push(cell.elements[0].widgetid);
      });
      wRoot.appendChild(row);
      if (i === layout.length) setTimeout(() => loadWgt(db, widgets), 1000);
    });
  }

  function onClickCancelButton() {
    onClose();
  }

  function widgetModalopen(val) {
    setWidgetModal(val);
    toggleFilterPanelDrill(false);
  }

  const renderErrorUI = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center access-denied-container">
        <img src={Access_denied} alt="Access Denied logo" />
        <h3 className="access-denied-header">Access Denied</h3>
        <p className="access-denied-description">
          You don't have access to Opria Dashboard.
          <br />
          If you need access to Opria Dashboard, please send an email to SynOps.support.
          <br />
          The team will reach-out to you with the next steps.
        </p>
      </div>
    );
  };
  useEffect(() => {
    if (activeObj.activeDahObj && activeObj.activeWidgets) {
      loadWgt(activeObj.activeDahObj, activeObj.activeWidgets);
    }
  }, [modalFullscreen]);

  return (
    <>
      {!shouldDisableModelView && !frame && (
        <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
      )}

      {shouldDisableModelView ? (
        fetching ? (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        ) : errorMessage ? (
          renderErrorUI()
        ) : (
          <>
            <div className="casestudy-modalimg new-dashboard-ui-casestudy-modaling">
              <div
                id="sisenseAppModal"
                className={!modalFullscreen ? "maximized-view-zoom" : "minimized-view-zoom"}
              />
            </div>

            <div className="csupdated-info" />
            {shouldShowFilters ? (
              <>
                <div
                  onClick={() => toggleFilterPanel(!isFilterPanelVisible)}
                  className={`filter-panel-toggle ${
                    isFilterPanelVisible ? "visible-filter-panel-toggle" : null
                  }`}
                >
                  {isFilterPanelVisible ? "»" : "«"}
                </div>
                <div
                  className={isFilterPanelVisible ? "visible-filter-panel" : "hide-filter-panel"}
                >
                  <div id="filter-panel"></div>
                </div>
              </>
            ) : null}
          </>
        )
      ) : (
        <Modal
          centered
          show
          onHide={onClickCancelButton}
          dialogClassName={
            view === "maximized"
              ? "modal-100w opsinsightiframe-modal"
              : "modal-90w opsinsightiframe-modal"
          }
          backdrop="static"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {clientName && offeringName && `${clientName} - ${offeringName}`}
            </Modal.Title>
            <button
              type="button"
              className="btn btn-primary mr-5"
              onClick={() => (view === "maximized" ? setView("minimized") : setView("maximized"))}
            >
              {view === "maximized" ? i18n.t("Minimize") : i18n.t("Maximize")}
              <i className="fas fa-compress-arrows-alt" />
            </button>
          </Modal.Header>

          <Modal.Body>
            {fetching ? (
              <div>Loading...</div>
            ) : errorMessage ? (
              <div>{errorMessage}</div>
            ) : (
              <>
                <div className="casestudy-modalimg">
                  <div id="sisenseAppModal" />
                </div>

                <div className="csupdated-info" />
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={widgetModal}
        onHide={() => widgetModalopen(false)}
        dialogClassName={
          childView === "maximized"
            ? "modal-100w opsinsightiframe-modal opsinsightiframe-modal-widget"
            : "modal-90w opsinsightiframe-modal opsinsightiframe-modal-widget"
        }
        backdrop="static"
      >
        <Modal.Header closeButton className="justify-content-end">
          <button
            type="button"
            className="btn btn-primary mr-5"
            onClick={() =>
              childView === "maximized" ? setChildView("minimized") : setChildView("maximized")
            }
          >
            {childView === "maximized" ? i18n.t("Minimize") : i18n.t("Maximize")}
            <i className="fas fa-compress-arrows-alt" />
          </button>
        </Modal.Header>
        <Modal.Body>
          {fetching ? (
            <div>Loading...</div>
          ) : errorMessage ? (
            <div>{errorMessage}</div>
          ) : (
            <>
              <div className="casestudy-modalimg new-dashboard-ui-casestudy-modaling">
                <div
                  id="widgetDB"
                  className={
                    childView === "maximized" ? "maximized-view-zoom" : "minimized-view-zoom"
                  }
                />
              </div>
              <div className="csupdated-info" />
              {shouldShowFiltersDrill ? (
                <>
                  <div
                    onClick={() => toggleFilterPanelDrill(!isFilterPanelVisibleDrill)}
                    className={`filter-panel-toggle ${
                      isFilterPanelVisibleDrill ? "visible-filter-panel-toggle" : null
                    }`}
                  >
                    {isFilterPanelVisibleDrill ? "»" : "«"}
                  </div>
                  <div
                    className={isFilterPanelVisibleDrill ? "visible-filter-panel" : "hide-filter-panel"}
                  >
                    <div id="filter-panel-drill"></div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  clientOpriaSisenseDetailsFromRedux: state.app.clientOpriaSisenseDetails,
});

export default connect(mapStateToProps, "")(SisenseDashboardIframe);
